import { useState, createRef } from "react";
import Button from "antd/lib/button/button";
import Input from "antd/lib/input";
import Form from "antd/lib/form/Form";
import Typography from "antd/lib/typography";
import Modal from "antd/lib/modal";
import axios from "../../apis/axios";
import Recaptcha from "react-google-recaptcha";
import { modifyModal, showModal } from "../../redux/modal/modal.actions";
import { store } from "../../redux/store";

const SignUpForm = ({ accountType }) => {
  const [form] = Form.useForm();
  const [formState, setFormState] = useState({ loading: false, msg: "" });
  const [isMsgModalVisible, setIsMsgModalVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [token, setToken] = useState(null);
  const recaptchaRef = createRef();

  const handleOk = () => {
    toggleMsgModal();
    store.dispatch(showModal(false));
  };

  const toggleMsgModal = () => {
    setIsMsgModalVisible(() => !isMsgModalVisible);
  };

  const onCaptchaChange = (t) => {
    setToken(t);
    setDisabled(false);
  };

  const onFinish = ({ email, name, password }) => {
    setFormState({ loading: true, msg: "" });

    const data = {
      email: email,
      name: name,
      password: password,
      user_type: accountType,
    };

    if (token) {
      axios
        .post("/user/register/", data)
        .then((response) => {
          return response.data;
        })
        .then((result) => {
          setFormState({ loading: false, msg: result.data.msg });
          toggleMsgModal();
        });
    }
  };

  const validatePassword = (rule, value, callback) => {
    if (value && value.length < 8) {
      callback("Your password must contain at least 8 characters.");
    } else if (!isNaN(value)) {
      callback("Your password can’t be entirely numeric.");
    } else {
      callback();
    }
  };

  return (
    <>
      <Form
        name="Sign Up Form"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        requiredMark={false}
        size="large"
        layout="vertical"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input your Name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          hasFeedback
          rules={[
            { required: true, message: "Please input your password!" },
            { validator: validatePassword },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            { required: true, message: "Please input your password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Recaptcha
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={recaptchaRef}
            onChange={onCaptchaChange}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={formState.loading}
            disabled={disabled}
          >
            Sign Up
          </Button>
          {formState.msg !== "" ? (
            <Modal
              title=""
              visible={isMsgModalVisible}
              onOk={handleOk}
              onCancel={handleOk}
              centered
              width={600}
            >
              <p style={{ fontSize: "1.2rem" }}>{formState.msg}</p>
            </Modal>
          ) : null}
        </Form.Item>
      </Form>
      <Typography>
        Already Have an account?{" "}
        <span
          style={{ color: "#00a1ed", cursor: "pointer" }}
          onClick={() =>
            store.dispatch(
              modifyModal({
                isSignUp: false,
                width: 400,
                title: "Sign In",
              })
            )
          }
        >
          Sign In
        </span>
      </Typography>
    </>
  );
};

export default SignUpForm;
