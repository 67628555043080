import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import modalReducer from "./modal/modal.reducer";
import boardsReducer from "./boards/boards.reducer";
import subjectReducer from "./subjects/subjects.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "modal"],
};

const rootReducer = combineReducers({
  user: userReducer,
  modal: modalReducer,
  subjects: subjectReducer,
  boards: boardsReducer,
});

export default persistReducer(persistConfig, rootReducer);
