import { useState, useEffect } from "react";
import Modal from "antd/lib/modal";
import SignUp from "./SignUp.component";
import SignIn from "./SignIn.component";
import { store } from "../../redux/store";
import { showModal } from "../../redux/modal/modal.actions";

const ModalComponent = () => {
  const [modalState, setModalState] = useState(store.getState().modal);

  store.subscribe(() => {
    let updatedModal = store.getState().modal;
    setModalState(updatedModal);
  });

  useEffect(() => {
    let updatedModal = store.getState().modal;
    setModalState(updatedModal);
  }, [modalState]);

  return (
    <Modal
      title={modalState.modalType.title}
      centered
      visible={modalState.modalVisible}
      footer={null}
      width={modalState.modalType.width}
      closable={modalState.closable}
      onCancel={() =>
        modalState.closable &&
        store.dispatch(showModal(!modalState.modalVisible))
      }
    >
      {modalState.modalType.isSignUp ? <SignUp /> : <SignIn />}
    </Modal>
  );
};

export default ModalComponent;
