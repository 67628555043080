import "./Tabs.styles.css";

const Tab = ({ activeTab, current, onClick }) => {
  let className = "tab-list-item";

  if (activeTab && activeTab === current) {
    className += " tab-list-active";
  }

  return (
    <li className={className} onClick={onClick}>
      {current.name}
    </li>
  );
};

export default Tab;
