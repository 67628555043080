import { useState, createRef } from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Layout from "antd/lib/layout";
import axios from "../../apis/axios";
import message from "antd/lib/message";
import Recaptcha from "react-google-recaptcha";
import { ReactComponent as ContactIllustration } from "../../assets/contact.svg";
import "./Contact.page.styles.css";

const { Content } = Layout;
const { TextArea } = Input;

const ContactPage = () => {
  const [form] = Form.useForm();
  const [token, setToken] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const recaptchaRef = createRef();

  const onFinish = async (values) => {
    if (token != null) {
      const { data } = await axios.post("/user/contact/", {
        ...values,
      });
      message.info(data.msg);
    }
  };

  const onCaptchaChange = (t) => {
    setToken(t);
    setDisabled(false);
  };

  return (
    <Content className="contact-container">
      <div className="contact-header">
        <h1>Contact us</h1>
      </div>
      <div className="contact-illustration">
        <ContactIllustration style={{ width: "40rem" }} />
      </div>
      <div className="contact-form">
        <Form
          form={form}
          name="contact"
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
          size="large"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="phone" label="Phone">
            <Input />
          </Form.Item>

          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true, message: "Please input some message!" }]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item>
            <Recaptcha
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={recaptchaRef}
              onChange={onCaptchaChange}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={disabled}>
              Send Message
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Content>
  );
};

export default ContactPage;
