import { SubjectActionTypes } from "./subjects.types";

const INITIAL_STATE = {
  data: [],
};

const subjectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubjectActionTypes.SET_SUBJECTS:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    default:
      return state;
  }
};

export default subjectReducer;
