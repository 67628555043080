import { Link } from "react-router-dom";
import useAxiosGetRequest from "../../apis/useAxiosGetRequest";
import { LoadingOutlined } from "@ant-design/icons";
import uuid from "react-uuid";
import "./Topic.styles.css";

const TopicComponent = (props) => {
  const { data, loading } = useAxiosGetRequest(
    `/content/topics/${props.chapter.id}`
  );

  return (
    <div className="topic-list">
      {!loading ? (
        data.map((item, index) => (
          <Link
            key={uuid()}
            to={`/watch/${item.id}/notSelected`}
            className="topic-list-anchor"
          >
            {index + 1} : {item.name}
          </Link>
        ))
      ) : (
        <LoadingOutlined style={{ fontSize: "2rem" }} />
      )}
    </div>
  );
};

export default TopicComponent;
