const Termspage = () => {
  return (
    <>
      <div className="page-header">
        <h1>Terms and Conditions</h1>
        <h3>OVERVIEW OF TERMS OF CONDITION AND USE OF THIS WEBSITE</h3>
      </div>
      <div className="page-container">
        <h4>Introduction</h4>
        <p>
          This website is operated by MUKTI. Throughout the site, the terms
          “we”, “us” and “our” refer to MUKTI. MUKTI offers this website,
          including all information, tools and services available from this site
          to you, the user, conditioned upon your acceptance of all terms,
          conditions, policies and notices stated here.
          <br />
          <br />
          By visiting our site and/ or donating to us, you engage in our
          “Service” and agree to be bound by the following terms and conditions
          (“Terms of Service”, “Terms”), including those additional terms and
          conditions and policies referenced herein and/or available by
          hyperlink. These Terms of Service apply to all users of the site,
          including without limitation users who are browsers, vendors,
          customers, merchants, and/ or contributors of content.
          <br />
          <br />
          Please read these Terms of Service carefully before accessing or using
          our website. By accessing or using any part of the site, you agree to
          be bound by these Terms of Service. If you do not agree to all the
          terms and conditions of this agreement, then you may not access the
          website or use any services. If these Terms of Service are considered
          an offer, acceptance is expressly limited to these Terms of Service.
          <br />
          <br />
          Any new features or tools which are added to the current store shall
          also be subject to the Terms of Service. You can review the most
          current version of the Terms of Service at any time on this page. We
          reserve the right to update, change or replace any part of these Terms
          of Service by posting updates and/or changes to our website. It is
          your responsibility to check this page periodically for changes. Your
          continued use of or access to the website following the posting of any
          changes constitutes acceptance of those changes.
          <br />
          <br />
          Minors or people below 18 years old are not allowed to use this
          Website.
          <br />
        </p>
        <h4>Intellectual Property Rights</h4>
        <p>
          Other than the content you own, under these Terms, MUKTI and/or its
          licensors own all the intellectual property rights and materials
          contained in this Website.
          <br />
          <br />
          You are granted limited license only for purposes of viewing the
          material contained on this Website.
        </p>
        <h4>Restrictions</h4>
        <p>
          You are specifically restricted from all of the following
          <br />
          <br />
          <li>publishing any Website material in any other media;</li>
          <li>
            selling, sublicensing and/or otherwise commercializing any Website
            material;
          </li>
          <li>publicly performing and/or showing any Website material;</li>
          <li>
            using this Website in any way that is or may be damaging to this
            Website;
          </li>
          <li>
            using this Website in any way that impacts user access to this
            Website;
          </li>
          <li>
            using this Website contrary to applicable laws and regulations, or
            in any way may cause harm to the Website, or to any person or
            business entity;
          </li>
          <li>
            engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website;
          </li>
          <li>using this Website to engage in any advertising or marketing.</li>
          <br />
          <br />
          Certain areas of this Website are restricted from being access by you
          and MUKTI may further restrict access by you to any areas of this
          Website, at any time, in absolute discretion. Any user ID and password
          you may have for this Website are confidential and you must maintain
          confidentiality as well.
        </p>
        <h4>Your Content</h4>
        <p>
          In these Website Standard Terms and Conditions, “Your Content” shall
          mean any audio, video text, images or other material you choose to
          display on this Website. By displaying Your Content, you grant MUKTI a
          non-exclusive, worldwide irrevocable, sub licensable license to use,
          reproduce, adapt, publish, translate and distribute it in any and all
          media.
          <br />
          <br />
          Your Content must be your own and must not be invading any
          third-party’s rights. MUKTI reserves the right to remove any of Your
          Content from this Website at any time without notice.
        </p>
        <h4>No warranties</h4>
        <p>
          This Website is provided “as is,” with all faults, and MUKTI express
          no representations or warranties, of any kind related to this Website
          or the materials contained on this Website. Also, nothing contained on
          this Website shall be interpreted as advising you.
        </p>
        <h4>Limitation of liability</h4>
        <p>
          In no event shall MUKTI, nor any of its officers, directors and
          employees, shall be held liable for anything arising out of or in any
          way connected with your use of this Website whether such liability is
          under contract. MUKTI, including its officers, directors and employees
          shall not be held liable for any indirect, consequential or special
          liability arising out of or in any way related to your use of this
          Website.
        </p>
        <h4>Indemnification</h4>
        <p>
          You hereby indemnify to the fullest extent MUKTI from and against any
          and/or all liabilities, costs, demands, causes of action, damages and
          expenses arising in any way related to your breach of any of the
          provisions of these Terms.
        </p>
        <h4>Severability</h4>
        <p>
          If any provision of these Terms is found to be invalid under any
          applicable law, such provisions shall be deleted without affecting the
          remaining provisions herein.
        </p>
        <h4>Variation of Terms</h4>
        <p>
          MUKTI is permitted to revise these Terms at any time as it sees fit,
          and by using this Website you are expected to review these Terms on a
          regular basis.
        </p>
        <h4>Assignment</h4>
        <p>
          The MUKTI is allowed to assign, transfer, and subcontract its rights
          and/or obligations under these Terms without any notification.
          However, you are not allowed to assign, transfer, or subcontract any
          of your rights and/or obligations under these Terms.
        </p>
        <h4>Entire Agreement</h4>
        <p>
          These Terms constitute the entire agreement between MUKTI and you in
          relation to your use of this Website, and supersede all prior
          agreements and understandings.
        </p>
        <h4>Governing Law & Jurisdiction</h4>
        <p>
          These Terms will be governed by and interpreted in accordance with the
          laws of India, and you submit to the non-exclusive jurisdiction of the
          state and federal courts located in West Bengal,India for the
          resolution of any disputes.
        </p>
        <h4>Contact Information</h4>
        <p>
          Questions about the Terms of Service should be sent to us at
          info@muktiweb.org.
        </p>
      </div>
    </>
  );
};

export default Termspage;
