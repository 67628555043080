import { Content } from "antd/lib/layout/layout";
import { ReactComponent as ReactLogo } from "../../assets/notFound.svg";

const NotFound = () => {
  return (
    <Content
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLogo />;
    </Content>
  );
};

export default NotFound;
