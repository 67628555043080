import { useEffect, useState } from "react";
import Comment from "antd/lib/comment";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import { store } from "../../redux/store";
import RepliesComponent from "../Replies/Replies.component";
import uuid from "react-uuid";

const SingleComment = ({ comment }) => {
  const [user, setUser] = useState(null);
  const [viewReplies, setViewReplies] = useState(false);
  const [showReplyEditor, setShowReplyEditor] = useState(false);

  useEffect(() => {
    let updatedUser = store.getState().user.currentUser;
    setUser(updatedUser);
  }, [user]);

  const toggleReplyEditor = () => {
    setShowReplyEditor(!showReplyEditor);
  };

  return (
    <>
      <Comment
        actions={[
          user != null && user.user_type === "T" && (
            <span
              key={uuid()}
              onClick={() => {
                toggleReplyEditor();
                setViewReplies(true);
              }}
            >
              Reply
            </span>
          ),
          <span
            key={uuid()}
            onClick={() => {
              setViewReplies(!viewReplies);
            }}
          >
            View Replies
          </span>,
        ]}
        author={comment.user}
        avatar={<Avatar size="medium" icon={<UserOutlined />} />}
        content={comment.comment}
        datetime={moment(comment.time).fromNow()}
      />
      {viewReplies && (
        <RepliesComponent
          comment={comment}
          showReplyEditor={showReplyEditor}
          toggleReplyEditor={toggleReplyEditor}
        />
      )}
    </>
  );
};

export default SingleComment;
