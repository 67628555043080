import { useState, useEffect } from "react";
import Rate from "antd/lib/rate";
import UserOutlined from "@ant-design/icons/UserOutlined";

const desc = ["terrible", "bad", "normal", "good", "wonderful"];

const Rating = ({ rating, handleRatingChange, reviews }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(rating);
  }, [rating]);

  const handleChange = (rate) => {
    setValue(rate);
    handleRatingChange(rate);
  };

  return (
    <span>
      <Rate
        tooltips={desc}
        onChange={handleChange}
        value={value}
        allowClear={false}
      />
      <span
        style={{ fontWeight: "300", marginLeft: "0.5rem", fontSize: "0.8rem" }}
      >
        <UserOutlined style={{ margin: 0 }} />
        {reviews}
      </span>
    </span>
  );
};

export default Rating;
