import axios from 'axios';
import { setModalClosable, showModal } from '../redux/modal/modal.actions';
import { store } from '../redux/store';
import { setCurrentUser } from '../redux/user/user.actions';

const url = process.env.REACT_APP_BACKEND_URL;

let user = null;

store.subscribe(async () => {
	user = await store.getState().user.currentUser;
	if (user)
		instance.defaults.headers.common[
			'Authorization'
		] = `JWT ${user.tokens.access}`;
});

console.log('checking user', user);

const instance = axios.create({
	baseURL: url,
	timeout: 5000,
});

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;
		if (typeof error.response === 'undefined') {
			// alert(
			//   `A server/network error occurred.\n
			//   Looks like CORS might be the problem.\n
			//   Sorry about this - we will get it fixed shortly.`
			// );
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = user.tokens.refresh;
			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				const now = Math.ceil(Date.now() / 1000);
				console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					return instance
						.post('/user/token/refresh/', { refresh: refreshToken })
						.then((response) => {
							user.tokens.access = response.data.access;
							console.log('token refreshed');
							axios.defaults.headers[
								'Authorization'
							] = `JWT ${response.data.access}`;
							originalRequest.headers[
								'Authorization'
							] = `JWT ${response.data.access}`;
							store.dispatch(setCurrentUser(user));
							return axios(originalRequest);
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					console.log('Refresh token is expired', tokenParts.exp, now);
					// alert('Token has Expired you need to login again');
					store.dispatch(setModalClosable(false));
					store.dispatch(showModal(true));
				}
			} else {
				console.log('Refresh token not available.');
				store.dispatch(setModalClosable(false));
				store.dispatch(showModal(true));
			}
		}

		return Promise.reject(error);
	},
);

export default instance;
