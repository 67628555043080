import { useEffect, useState } from "react";
import Comment from "antd/lib/comment";
import Avatar from "antd/lib/avatar/avatar";
import message from "antd/lib/message";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import Editor from "../CommentEditor/Editor.component";
import axios from "../../apis/axios";
import { store } from "../../redux/store";
import uuid from "react-uuid";

const RepliesComponent = ({ comment, showReplyEditor, toggleReplyEditor }) => {
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    let updatedUser = store.getState().user.currentUser;
    setUser(updatedUser);
  }, [user]);

  const { id } = comment;
  const url = `/user/replies/${id}`;
  useEffect(() => {
    setLoading(true);
    async function getReplies() {
      try {
        const { data } = await axios.get(url);
        setReplies(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    getReplies();
  }, [url]);

  console.log("rendered");

  const handleSubmit = async (value, setValue) => {
    if (value.length > 0) {
      setSubmitting(true);
      try {
        const { data } = await axios.post("/user/add_reply/", {
          reply: value,
          comment_id: id,
        });
        setReplies(() => [
          {
            user: user.name,
            reply: value,
            time: moment(new Date()).fromNow(),
          },
          ...replies,
        ]);
        message.info(data.msg);
        toggleReplyEditor();
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
        setValue("");
      }
    }
  };

  return (
    <div>
      <Comment>
        <>
          {showReplyEditor && (
            <Editor
              btnText={"Add Reply"}
              submitting={submitting}
              handleSubmit={handleSubmit}
            />
          )}
          {!loading &&
            replies.map((reply) => (
              <Comment
                key={uuid()}
                author={reply.user}
                avatar={<Avatar size="medium" icon={<UserOutlined />} />}
                content={reply.reply}
                datetime={moment(reply.time).fromNow()}
              />
            ))}
        </>
      </Comment>
    </div>
  );
};

export default RepliesComponent;
