import { Link } from "react-router-dom";

const PrivacyPage = () => {
  return (
    <>
      <div className="page-header">
        <h1>Privacy Policy</h1>
      </div>
      <div className="page-container">
        <p>
          Thank you for visiting the MUKTI Website and reviewing our Privacy
          Notice. This statement addresses collection, use, security of, and
          access to information that may be obtained through use of MUKTI
          website. Personal information is information about you that is
          personally identifiable like your name, address, email address, or
          phone number, and that is not otherwise publicly available.
        </p>
        <h4>Information Collected and How it is Used</h4>
        <p>
          MUKTI collects personal information when you register on this site,
          when you visit this Website, and use the Services or access the
          Content. When you register on this Website, we ask for information
          such as your name, e-mail address, mailing address, phone number etc.
          Once you register with us and/or Log-in, you are not anonymous to us
          anymore. If simply visit this site without login we automatically
          collect few information such as IP address, type of browser,
          date/time, page you visit etc. this information doesn’t identify you
          personally, but we need them in order to customize the content you are
          viewing and to fulfill your request.
        </p>
        <h4>Information Sharing and Disclosure</h4>
        <p>
          MUKTI website does not rent, sell, or share Information with other
          people or non affiliated entities except to provide services that you
          have requested when we have your permission or under the circumstances
          detailed below.
          <br />
          <br />
          MUKTI reserves the right to conduct research including, but not
          limited to, statistical and other forms of analyses based on the
          Information. Using such research and analyses and the Information
          collected from you, MUKTI reserves the right to send you certain
          communications relating to MUKTI and the Content and Services, such as
          announcements, administrative messages and newsletters, without
          offering you the opportunity to opt-out of receiving them.
          <br />
          <br />
          MUKTI reserves the right to provide the Information to trusted
          partners who work on behalf of or with MUKTI within the bounds of
          confidentiality.
          <br />
          <br />
          MUKTI also reserves the right to disclose the Information when
          required by law or regulation, or when we believe it is necessary to
          share Information in order to investigate, prevent, or take action
          regarding illegal activities, suspected fraud, situations involving
          potential threats to the physical safety of any person, violations of
          terms of use of MUKTI, or as otherwise required by law. MUKTI will not
          disclose the individuals e-mail id except the registered members
          unless otherwise required by law and enforcement policy of India
          Government. If you are not interested to disclose your e-mail id to
          registered member you should not register into this site.
          <br />
          <br />
          If you believe that your personal/private information is being used
          for a purpose other than what was intended when submitted, you may
          contact with us through website <Link to="/contact">
            Contact us
          </Link>{" "}
          page.
          <br />
          <br />
        </p>
        <h4>Email Communication from MUKTI</h4>
        <p>
          We usually send weekly, monthly or yearly reports to our registered
          customers/donors to let them know about status of various activities
          and projects. We also allow our users to subscribe our newsletters and
          we send email newsletter with updates and promotions on activities of
          MUKTI. Subscribers are having feature to unsubscribe from those
          distribution list at any time by following simple instruction contains
          in email. Unsubscribing from from newsletter will not discontinue the
          service update to customers/donors.
        </p>
        <h4>Copyright Protection</h4>
        <p>
          All content on this site including article, images, text, audio, video
          and software are the sole property of MUKTI. These all contents are
          protected by Indian and international copyright laws.
          <br />
          <br />
          Any redistribution or reproduction of part or all of the contents in
          any form is prohibited other than the following
          <br />
          <li>
            You may print or download to a local hard disk extracts for your
            personal and non-commercial use only
          </li>
          <li>
            You may copy the content to individual third parties for their
            personal use, but only if you acknowledge the website as the source
            of the material
          </li>
          <li>
            You may not, except with our express written permission, distribute
            or commercially exploit the content. Nor may you transmit it or
            store it in any other website or other form of electronic retrieval
            system.
          </li>
        </p>
      </div>
    </>
  );
};

export default PrivacyPage;
