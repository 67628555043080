import AimOutlined from "@ant-design/icons/AimOutlined";
import AuditOutlined from "@ant-design/icons/AuditOutlined";
import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined";
import "./about.page.style.css";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-header">
        <h1>About Mukti</h1>
        <h3>WHY WE ARE HERE, FORMATION, LEGAL STATUS AND OUR VOLUNTEERS</h3>
      </div>
      <div className="page-container">
        <p>{t("aboutOne")}</p>
        <p>{t("aboutTwo")}</p>
        <p>{t("aboutThree")}</p>
        <h4>
          <AimOutlined /> {t("about-header1")}
        </h4>
        <p>{t("aboutFour")}</p>
        <h4>
          <AuditOutlined /> {t("about-header2")}
        </h4>
        <p>{t("aboutFifth")}</p>
        <h4>
          <UsergroupAddOutlined />
          {t("about-header3")}
        </h4>
        <p>{t("aboutSixth")}</p>
      </div>
    </>
  );
};

export default AboutPage;
