import uuid from "react-uuid";
import { stds } from "../../assets/Standard";

import "./StandardTab.styles.css";
// the hook
import { useTranslation } from "react-i18next";

const StandardTab = ({ onItemSelected }) => {
  const { t } = useTranslation();

  return (
    <div className="tabs-main">
      {stds.map((item) => (
        <div
          key={uuid()}
          style={{
            backgroundColor: `${item.color}`,
          }}
          className="tabs-inner"
          onClick={() => onItemSelected(item.name)}
        >
          <h2 className="tab-name">
            {t("class")} {item.name}
          </h2>
        </div>
      ))}
    </div>
  );
};

export default StandardTab;
