import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import translationEn from "../locales/en/translationEN.json"
import translationBN from "../locales/bn/translationBN.json"
import translationHIN from "../locales/hin/translationHIN.json"

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEn
  },
  bn: {
    translation: translationBN
  },
  hin: {
    translation: translationHIN
  }
};

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
     fallbackLng: "en", // use en if detected lng is not available

     saveMissing: true, // send not translated keys to endpoint

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;