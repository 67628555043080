import { useState, useEffect } from "react";
import Empty from "antd/lib/empty";
import uuid from "react-uuid";
import StandardComponent from "../Standard/Standard.component";
import { store } from "../../redux/store";
import { setBoards } from "../../redux/boards/boards.action";
import { stds } from "../../assets/Standard";
import BoardsTab from "../BoardsTab/BoardsTab.component";
import axios from "../../apis/axios";
import "./Courses.styles.css";
import MediumTab from "../MediumTab/MediumTab.component";
import { MediumData } from "../../assets/Medium";
import { Skeleton } from "antd";

const CoursesComponent = ({ boardsData }) => {
  const [board, setBoard] = useState(null);
  const [medium, setMedium] = useState(null);
  const [noData, setNoData] = useState(false);
  const [subjectsData, setSubjectsData] = useState({
    data: null,
    loading: true,
  });

  useEffect(() => {
    if (board === null) {
      if (boardsData.data && boardsData.data.length > 0) {
        setBoard(boardsData.data[0]);
        store.dispatch(setBoards(boardsData.data));
      } else setNoData(true);
    }

    async function getSubjects() {
      try {
        if (board) {
          const { data } = await axios.get(
            `/content/subjects/?board=${board.name}&medium=${medium.name}`
          );
          setSubjectsData({ data, loading: false });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (medium === null) setMedium(MediumData[0]);
    getSubjects();
  }, [boardsData, board, medium]);

  const onBoardSelect = (item) => {
    setBoard(item);
  };

  const onMediumSelect = (item) => {
    setMedium(item);
  };

  return (
    <>
      {!noData ? (
        <div className="courses">
          {!boardsData.loading && boardsData.data.length > 0 && (
            <>
              <BoardsTab
                boardsData={boardsData.data}
                onTabSelected={onBoardSelect}
              />
              <MediumTab
                mediumData={MediumData}
                onTabSelected={onMediumSelect}
              />
              {!subjectsData.loading ? (
                <div className="grid-parent">
                  <div className="grid">
                    {stds.map((item) => (
                      <StandardComponent
                        key={uuid()}
                        std={item.name}
                        color={item.color}
                        medium={medium}
                        board={board}
                        subjectsData={subjectsData}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <Skeleton paragraph={{ rows: 4 }} />
              )}
            </>
          )}
        </div>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default CoursesComponent;
