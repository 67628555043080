import { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Layout from "antd/lib/layout/layout";
import Navbar from "../Navbar/Navbar.component";
import EmailStatus from "../../views/EmailStatus/EmailStatus.component";
import HomeComponent from "../../views/HomePage/Home.page";
import FooterComponent from "../Footer/Footer.component";
import SubjectPage from "../../views/SubjectPage/Subject.page";
import VideoPage from "../../views/VideoPage/Video.page";
import AboutPage from "../../views/AboutPage/About.component";
import TermsPage from "../../views/TermsOfServices/Terms.page.component";
import PrivacyPage from "../../views/PrivacyPolicy/Privacy.Page";
import ContactPage from "../../views/Contactpage/Contact.page";
import PasswordReset from "../../views/PasswordReset/PasswordReset.Page";
import { store } from "../../redux/store";
import { setModalObstrusive } from "../../redux/modal/modal.actions";
import NotFound from "../NotFound/NotFound.component";
import DownloadPage from "../../views/DownloadPage/Download.page";

function App() {
  useEffect(() => {
    setTimeout(() => {
      if (
        !store.getState().user.currentUser &&
        !store.getState().modal.obtrusive
      ) {
        store.dispatch(
          setModalObstrusive({
            closable: false,
            modalVisible: true,
            modalType: {
              isSignUp: true,
              width: 600,
              title: "Sign up as",
            },
            accountType: "",
            obtrusive: true,
          })
        );
      }
    }, 600000);
  }, []);

  return (
    <BrowserRouter>
      <Layout style={{ height: `100%`, backgroundColor: "#fff" }}>
        <Navbar />
        <Switch>
          <Route exact path="/" component={HomeComponent} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route
            exact
            path="/courses/:boardName/:medium/:std/:subName"
            component={SubjectPage}
          />
          <Route path="/watch/:topicId/:videoId" component={VideoPage} />
          <Route exact path="/termsofservices" component={TermsPage} />
          <Route exact path="/privacypolicy" component={PrivacyPage} />
          <Route
            exact
            path="/account/email_valid/:status"
            component={EmailStatus}
          />
          <Route
            exact
            path="/password_reset/:uidb64/:token"
            component={PasswordReset}
          />
          <Route exact path="/download" component={DownloadPage} />
          <Route path="*" component={NotFound} />
        </Switch>
        <FooterComponent />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
