import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Typography from "antd/lib/typography";
import Button from "antd/lib/button/button";
import Card from "antd/lib/card";
import { Content } from "antd/lib/layout/layout";
import { store } from "../../redux/store";
import { showModal, modifyModal } from "../../redux/modal/modal.actions";

const { Title } = Typography;

const MessageBox = ({ msg }) => {
  const [user, setUser] = useState(null);
  const history = useHistory();

  store.subscribe(() => {
    let updatedUser = store.getState().user.currentUser;
    setUser(updatedUser);
  });

  useEffect(() => {
    let updatedUser = store.getState().user.currentUser;
    setUser(updatedUser);
    if (user) history.push("/");
  }, [user, history]);

  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
      }}
    >
      <Card bordered={false} style={{ width: 600, margin: "auto" }}>
        <Title level={5}>{msg}</Title>
        <Button
          type="primary"
          size="large"
          onClick={() => {
            store.dispatch(
              modifyModal({
                isSignUp: false,
                width: 400,
                title: "Sign in",
              })
            );
            store.dispatch(showModal(true));
          }}
        >
          Login
        </Button>
      </Card>
    </Content>
  );
};

const EmailStatus = () => {
  let { status } = useParams();
  if (status === "true")
    return <MessageBox msg="Email Verification complete. Now you can login" />;
  else if (status === "expired")
    return <MessageBox msg="Token Expired. Try Again." />;
  else if (status === "invalid") return <MessageBox msg="Token Invalid..." />;
};

export default EmailStatus;
