export const MediumData = [
	{
		id: 2,
		name: 'বাংলা',
	},
	{
		id: 1,
		name: 'English',
	},
	// {
	//   id: 3,
	//   name: "हिंदी",
	// },
];
