import { useState, useEffect } from "react";
import Tabs from "../Tabs/Tabs.component";
import "./BoardsTab.styles.css";
import { useTranslation } from "react-i18next";

const BoardsTab = ({ boardsData, onTabSelected, activeTab }) => {
  const [active, setActive] = useState(null);

  useEffect(() => {
    if (active === null && boardsData) {
      setActive(boardsData[0]);
    }
    if (activeTab) {
      setActive(activeTab);
    }
  }, [boardsData, active, activeTab]);

  const { t } = useTranslation();

  const onClickTabItem = (item) => {
    setActive(item);
    onTabSelected(item);
  };

  return (
    <div className="boards-tabs">
      <div className="boards-header">
        <span>{t("board")}:</span>
      </div>
      <Tabs list={boardsData} active={active} onClickTabItem={onClickTabItem} />
    </div>
  );
};

export default BoardsTab;
