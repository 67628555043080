import { useState, useEffect } from "react";
import Layout from "antd/lib/layout";
import Button from "antd/lib/button/button";
import {
  modifyModal,
  showModal,
  setModalAccountType,
} from "../../redux/modal/modal.actions";
import { store } from "../../redux/store";
import { useTranslation } from "react-i18next";
import CoursesComponent from "../../components/Courses/Courses.component";
import useAxiosGetRequest from "../../apis/useAxiosGetRequest";
import "./Home.page.styles.css";
import { Skeleton } from "antd";
const { Content } = Layout;

const Home = () => {
  const [user, setUser] = useState(null);

  const { t } = useTranslation();

  store.subscribe(() => {
    let updatedUser = store.getState().user.currentUser;
    setUser(updatedUser);
  });

  useEffect(() => {
    let updatedUser = store.getState().user.currentUser;
    setUser(updatedUser);
  }, [user]);
  const boardsData = useAxiosGetRequest("/content/boards/");

  return (
    <Content style={{ height: "100%" }}>
      <div className="home-back">
        <div className="home-inner">
          <h1 className="home-title">Mukti Academy</h1>
          <p className="home-para">Digital education 4.0 for everyone</p>
          {!user && (
            <Button
              type="primary"
              shape="round"
              size="large"
              style={{ height: "3rem", width: "12rem" }}
              onClick={() => {
                store.dispatch(setModalAccountType(""));
                store.dispatch(
                  modifyModal({
                    isSignUp: true,
                    width: 600,
                    title: "Sign up as",
                  })
                );
                store.dispatch(showModal(true));
              }}
            >
              {t("home-btn")}
            </Button>
          )}
        </div>
      </div>
      {!boardsData.loading ? (
        <>
          <CoursesComponent boardsData={boardsData} />
        </>
      ) : (
        <Skeleton active style={{ fontSize: "3rem", margin: "auto" }} />
      )}
    </Content>
  );
};

export default Home;
