import uuid from "react-uuid";
import Tab from "./Tab.component";
import "./Tabs.styles.css";

const Tabs = ({ list, active, onClickTabItem }) => (
  <div className="tabs">
    <ol className="tab-list">
      {list &&
        list.map((item) => (
          <Tab
            key={uuid()}
            activeTab={active}
            current={item}
            onClick={() => {
              onClickTabItem(item);
            }}
          />
        ))}
    </ol>
  </div>
);

export default Tabs;
