import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player/youtube";
import Typography from "antd/lib/typography";
import Button from "antd/lib/button/button";
import message from "antd/lib/message";
import { store } from "../../redux/store";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import axios from "../../apis/axios";
import Rating from "../Rating/Rating.component";
import "./VideoPlayer.styles.css";

const { Title } = Typography;

const VideoPlayer = (props) => {
  const [user, setUser] = useState(null);
  const [rated, setRated] = useState(false);
  const history = useHistory();

  store.subscribe(() => {
    let updatedUser = store.getState().user.currentUser;
    setUser(updatedUser);
  });

  useEffect(() => {
    let updatedUser = store.getState().user.currentUser;
    setUser(updatedUser);
  }, []);

  const prefix = "https://www.youtube.com/embed/";

  const handleRatingChange = async (rating) => {
    if (!rated) {
      const { data } = await axios.post("/content/rating/", {
        video_id: props.videoData.id,
        rating,
      });
      message.info(data.msg);
      setRated(true);
    } else message.info("Already Rated!!");
  };

  const goToDownloadPage = () => {
    history.push({
      pathname: "/download",
      state: {
        video: props.videoData,
      },
    });
  };

  return (
    <div className="player">
      <ReactPlayer
        width={"100%"}
        url={prefix + props.videoData.code}
        stopOnUnmount={true}
        controls={true}
        className="mobile-View"
      />
      <Title level={3} className="mobile-View-text">
        {props.videoData.name}
      </Title>
      <div style={btnDiv}>
        {user && (
          <>
            <Rating
              rating={props.videoData.rating}
              handleRatingChange={handleRatingChange}
              reviews={props.videoData.reviews}
            />
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              size="large"
              shape="round"
              onClick={goToDownloadPage}
              className="mobile-View-btn"
            >
              Download
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const btnDiv = {
  marginTop: "0.5rem",
  display: "flex",
  justifyContent: "space-between",
};

export default VideoPlayer;
