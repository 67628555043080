import { Link } from "react-router-dom";
import Layout from "antd/lib/layout";
import FacebookFilled from "@ant-design/icons/FacebookFilled";
import LinkedinFilled from "@ant-design/icons/LinkedinFilled";
import MailFilled from "@ant-design/icons/MailFilled";
import TwitterCircleFilled from "@ant-design/icons/TwitterCircleFilled";
import YoutubeFilled from "@ant-design/icons/YoutubeFilled";
import "./Footer.styles.css";

const { Footer } = Layout;

const social = {
  fontSize: "1.5rem",
  margin: "0 0.8rem 0 0",
  color: "#00a1ed",
};

const center = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const FooterComponent = () => {
  const goToLink = (url) => {
    window.open(url);
  };
  return (
    <Footer>
      <div style={center}>
        <MailFilled
          style={social}
          onClick={() => goToLink("mailto:info@muktiweb.org")}
        />
        <FacebookFilled
          style={social}
          onClick={() => goToLink("https://www.facebook.com/muktiweb/")}
        />
        <LinkedinFilled
          style={social}
          onClick={() => goToLink("http://www.linkedin.com/companies/1103137")}
        />
        <YoutubeFilled
          style={social}
          onClick={() => goToLink("http://www.youtube.com/user/muktiweb")}
        />
        <TwitterCircleFilled
          style={social}
          onClick={() => goToLink("https://www.twitter.com/muktiindia")}
        />
      </div>
      <div className="address-div">
        <p className="address">
          MUKTI Site Office:
          <br /> Vill+Post : Purba Sridharpur, Raidighi
          <br />
          24 Pargs(south) WB, India, Pin: 743383 <br />
          Phone : +91 9733981715
        </p>
        <p className="address">
          MUKTI City Office:
          <br /> 22, Canal Side Road Garia, Kolkata - 700084
          <br /> Phone : +91 33 24625544
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        <p>
          <Link to="/privacypolicy">Privacy Policy</Link> |{" "}
          <Link to="/termsofservices">Terms and Conditions</Link>
        </p>
        <p style={{ fontWeight: "300" }}>
          LMS v{`${process.env.REACT_APP_VERSION}`}
        </p>
        <p style={{ fontWeight: "300" }}>
          Mukti Foundation &copy; {new Date().toISOString().slice(0, 4)}
        </p>
      </div>
    </Footer>
  );
};

export default FooterComponent;
