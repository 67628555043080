import { useState } from "react";
import Typography from "antd/lib/typography";
import SignInForm from "../Forms/SignIn.form.component";
import { store } from "../../redux/store";
import {
  modifyModal,
  setModalAccountType,
} from "../../redux/modal/modal.actions";

const SignIn = () => {
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  return (
    <>
      <SignInForm isPasswordReset={isPasswordReset} />
      {!isPasswordReset && (
        <Typography
          style={{
            textAlign: "right",
            color: "#00a1ed",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsPasswordReset(true);
            store.dispatch(
              modifyModal({
                isSignUp: false,
                width: 400,
                title: "Request a reset email",
              })
            );
          }}
        >
          Forgot Password?
        </Typography>
      )}
      <Typography>
        Dont Have an account?{" "}
        <span
          style={{ color: "#00a1ed", cursor: "pointer" }}
          onClick={() => {
            const type = store.getState().modal.accountType;
            if (type !== "") store.dispatch(setModalAccountType(""));
            store.dispatch(
              modifyModal({
                isSignUp: true,
                width: 600,
                title: "Sign up as",
              })
            );
          }}
        >
          Sign Up
        </span>
      </Typography>
    </>
  );
};

export default SignIn;
