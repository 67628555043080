import { useState, useEffect } from "react";
import axios from "./axios";

const useAxiosGetRequest = (url) => {
  const [state, setState] = useState({ data: null, loading: true });

  useEffect(() => {
    setState({ data: null, loading: true });
    axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .then((data) => setState({ data: data, loading: false }));
  }, [url]);
  return state;
};

export default useAxiosGetRequest;
