import { useState, useEffect } from "react";
import Empty from "antd/lib/empty";
import { useParams, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import axios from "../../apis/axios";
import useAxiosGetRequest from "../../apis/useAxiosGetRequest";
import BoardsTab from "../../components/BoardsTab/BoardsTab.component";
import StandardTab from "../../components/StandardTab/StandardTab.component";
import SubjectsTab from "../../components/SubjectsTab/SubjectsTab.componet";
import TopicComponent from "../../components/TopicComponent/Topic.component";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import MediumTab from "../../components/MediumTab/MediumTab.component";
import { stds } from "../../assets/Standard";
import { MediumData } from "../../assets/Medium";
import { store } from "../../redux/store";
import { useTranslation } from "react-i18next";

import "./Subject.styles.css";

const SubjectPage = () => {
  const { t } = useTranslation();
  let { boardName, medium, std, subName } = useParams();
  const history = useHistory();
  const color = stds.find((s) => s.name === std);
  const [boardsData, setBoardsData] = useState(null);
  const [subjectsData, setSubjectsData] = useState({
    data: null,
    loading: true,
  });

  const { data, loading } = useAxiosGetRequest(
    `/content/chapters/?board=${boardName}&medium=${medium}&std=${std}&subName=${subName}`
  );

  useEffect(() => {
    const getBoard = async () => {
      const board = store.getState().boards.data;
      if (board.length === 0) {
        try {
          const { data } = await axios.get("/content/boards");
          setBoardsData(data);
        } catch (err) {
          console.error(err);
        }
      } else {
        setBoardsData(board);
      }
    };
    const getSubjects = async () => {
      try {
        const { data } = await axios.get(
          `/content/subjects/?boardName=${boardName}`
        );
        setSubjectsData({ data, loading: false });
      } catch (err) {
        console.error(err);
      }
    };
    getSubjects();
    getBoard();
  }, [boardName]);

  const changeHistory = () => {
    history.push(`/courses/${boardName}/${medium}/${std}/${subName}`);
  };

  const onBoardSelected = (item) => {
    boardName = item.name;
    changeHistory();
  };

  const onStdSelected = (item) => {
    std = item;
    changeHistory();
  };

  const onSubjectSelected = (item) => {
    subName = item;
    changeHistory();
  };

  const onMediumSelected = (item) => {
    medium = item.name;
    changeHistory();
  };

  return (
    <>
      {boardsData && (
        <>
          <BoardsTab
            boardsData={boardsData}
            onTabSelected={onBoardSelected}
            activeTab={boardsData.find((item) => item.name === boardName)}
          />
          <MediumTab
            mediumData={MediumData}
            onTabSelected={onMediumSelected}
            activeTab={boardsData.find((item) => item.name === medium)}
          />
        </>
      )}
      <StandardTab onItemSelected={onStdSelected} />
      {!subjectsData.loading && (
        <>
          <SubjectsTab
            subjectsData={subjectsData.data}
            onTabSelected={onSubjectSelected}
            activeTab={subjectsData.data.find((item) => item.name === subName)}
            color={color.color}
          />
        </>
      )}
      <div style={{ width: "80%", margin: "auto" }}>
        {!loading ? (
          <>
            <h1 style={{ color: `${color.color}` }} className="heading-one">
              {subName} {std}
            </h1>
            {data.length > 0 ? (
              data.map((item, index) => (
                <div key={uuid()} style={{ overflow: "hidden" }}>
                  <h2
                    style={{ padding: "10px", color: `${color.color}` }}
                    className="heading-two"
                  >
                    {t("chapter")} {index + 1}: {item.name}
                  </h2>
                  <TopicComponent chapter={item} />
                </div>
              ))
            ) : (
              <Empty />
            )}
          </>
        ) : (
          <LoadingOutlined style={{ fontSize: "3rem" }} />
        )}
      </div>
    </>
  );
};

export default SubjectPage;
