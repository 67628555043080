import Button from "antd/lib/button/button";
import Student from "../../assets/student.svg";
import Teacher from "../../assets/teacher.svg";
import SignUpForm from "../Forms/SignUp.form.component";
import { store } from "../../redux/store";
import {
  modifyModal,
  setModalAccountType,
} from "../../redux/modal/modal.actions";

const SignUp = () => {
  const AccountTypeSelected = (type) => {
    store.dispatch(setModalAccountType(type));

    const typeText = type === "S" ? "Student" : "Teacher";
    store.dispatch(
      modifyModal({
        isSignUp: true,
        width: 400,
        title: `Sign up as ${typeText}`,
      })
    );
  };

  return (
    <>
      {store.getState().modal.accountType !== "" ? (
        <SignUpForm accountType={store.getState().modal.accountType} />
      ) : (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div style={makeStyle}>
            <img src={Student} alt="student-svg" width="200" height="150" />
            <Button
              type="primary"
              size="large"
              style={{ marginTop: "1rem", width: "12rem" }}
              onClick={() => AccountTypeSelected("S")}
            >
              I am a Student
            </Button>
          </div>
          <div style={makeStyle}>
            <img src={Teacher} alt="teacher-svg" width="200" height="150" />
            <Button
              type="primary"
              size="large"
              style={{ marginTop: "1rem", width: "12rem" }}
              onClick={() => AccountTypeSelected("T")}
            >
              I am a Teacher
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const makeStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
};

export default SignUp;
