import { useEffect, useState } from "react";
import Menu from "antd/lib/menu";
import Typography from "antd/lib/typography";
import Button from "antd/lib/button/button";
import Dropdown from "antd/lib/dropdown/dropdown";
import { Link } from "react-router-dom";
import ModalComponent from "../Modal/Modal.component";
import { store } from "../../redux/store";
import { setCurrentUser } from "../../redux/user/user.actions";
import { showModal, modifyModal } from "../../redux/modal/modal.actions";
import axios from "../../apis/axios";
import DownOutlined from "@ant-design/icons/DownOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import LoginOutlined from "@ant-design/icons/LoginOutlined";
import MuktiLogo from "../../assets/MuktiLogo.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import "./Navbar.styles.css";

const { Title } = Typography;

const changeLanguage = (ln) => {
  return () => {
    i18n.changeLanguage(ln);
    console.log(`language changed ${ln}`);
  };
};

const Navbar = () => {
  const [user, setUser] = useState(null);

  const { t } = useTranslation();

  store.subscribe(() => {
    let updatedUser = store.getState().user.currentUser;
    setUser(updatedUser);
  });

  useEffect(() => {
    let updatedUser = store.getState().user.currentUser;
    setUser(updatedUser);
  }, [user]);

  const handleLogOut = () => {
    axios
      .post("/user/logout/", { refresh: user.tokens.refresh })
      .catch((err) => console.error(err));
    store.dispatch(setCurrentUser(null));
  };

  const hamburger = () => {
    document.querySelector(".hamburger").classList.toggle("active");
    document.querySelector(".nav-menu").classList.toggle("active");
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={changeLanguage("en")}>
        <span>
          <b>A</b> English
        </span>
      </Menu.Item>
      <Menu.Item key="2" onClick={changeLanguage("bn")}>
        <span>
          <b>আ</b> Bengali
        </span>
      </Menu.Item>
      <Menu.Item key="3" onClick={changeLanguage("hin")}>
        <span>
          <b>आ</b> Hindi
        </span>
      </Menu.Item>
    </Menu>
  );
  const content = (
    <Menu>
      <Menu.Item key="1">
        <p
          type="primary"
          size="medium"
          style={{ margin: "2px", color: "red" }}
          onClick={() => handleLogOut()}
        >
          <LoginOutlined />
          &nbsp; Log Out
        </p>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <header className="header">
        <nav className="navbar">
          <Link to="/">
            <div className="branding">
              <img src={MuktiLogo} alt="logo" className="logo" />
              <Title level={4} style={{ margin: 0 }}>
                Mukti Academy
              </Title>
            </div>
          </Link>
          <ul className="nav-menu">
            <li className="nav-item" onClick={hamburger}>
              <Link to="">{t("nav-home")}</Link>
            </li>
            <li className="nav-item" onClick={hamburger}>
              <Link to="/about">{t("nav-about")}</Link>
            </li>
            <li className="nav-item" onClick={hamburger}>
              <Link to="/contact">{t("nav-contact")}</Link>
            </li>
            {user ? (
              <li className="nav-item">
                <Dropdown overlay={content}>
                  <p style={{ border: "0", boxShadow: "none", margin: "2px" }}>
                    <UserOutlined /> &nbsp;
                    {user.name}
                  </p>
                </Dropdown>
              </li>
            ) : (
              <li className="nav-item" onClick={hamburger}>
                <Button
                  type="primary"
                  size="medium"
                  onClick={() => {
                    store.dispatch(
                      modifyModal({
                        isSignUp: false,
                        width: 400,
                        title: "Sign in",
                      })
                    );
                    store.dispatch(showModal(true));
                  }}
                >
                  Sign In
                </Button>
              </li>
            )}
            <li className="nav-item">
              <Dropdown overlay={menu}>
                <Button defaultValue="0">
                  Choose Language
                  <DownOutlined />
                </Button>
              </Dropdown>
            </li>
          </ul>
          <div className="hamburger" onClick={hamburger}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </header>
      <ModalComponent />
    </>
  );
};

export default Navbar;
