import { ModalActionTypes } from "./modal.types";

export const showModal = (payload) => ({
  type: ModalActionTypes.SHOW_MODAL,
  payload,
});

export const modifyModal = (payload) => ({
  type: ModalActionTypes.MODIFY_MODAL,
  payload,
});

export const setModalClosable = (payload) => ({
  type: ModalActionTypes.SET_MODAL_CLOSABLE,
  payload,
});

export const setModalObstrusive = (payload) => ({
  type: ModalActionTypes.SET_OBSTRUSIVE_MODAL,
  payload,
});

export const setModalAccountType = (payload) => ({
  type: ModalActionTypes.SET_MODAL_ACCOUNT_TYPE,
  payload,
});
