import { useState, useEffect } from "react";
import Tabs from "../Tabs/Tabs.component";
// import { useTranslation } from "react-i18next";

const MediumTab = ({ mediumData, onTabSelected, activeTab }) => {
  const [active, setActive] = useState(null);

  useEffect(() => {
    if (active === null && mediumData) {
      setActive(mediumData[0]);
    }
    if (activeTab) {
      setActive(activeTab);
    }
  }, [mediumData, active, activeTab]);

  // const { t } = useTranslation();

  const onClickTabItem = (item) => {
    setActive(item);
    onTabSelected(item);
  };

  return (
    <div className="boards-tabs">
      <div className="boards-header">
        <span>Choose Medium:</span>
      </div>
      <Tabs list={mediumData} active={active} onClickTabItem={onClickTabItem} />
    </div>
  );
};

export default MediumTab;
