import { useState } from "react";
import Button from "antd/lib/button/button";
import Input from "antd/lib/input";
import Form from "antd/lib/form/Form";
import Typography from "antd/lib/typography";
import message from "antd/lib/message";
import axios from "../../apis/axios";
import { store } from "../../redux/store";
import { setCurrentUser } from "../../redux/user/user.actions";
import { showModal, setModalObstrusive } from "../../redux/modal/modal.actions";

let error = false;

const SignInForm = ({ isPasswordReset }) => {
  const [formState, setFormState] = useState({ loading: false });
  const [authError, setAuthError] = useState("");

  const onFinish = (values) => {
    setFormState({ loading: true });
    send(values);
  };

  const send = async (values) => {
    if (!isPasswordReset) {
      await axios
        .post("/user/login/", values)
        .then((res) => res.data)
        .then((data) => {
          message.info(`${data.msg}`);
          if (data.msg !== "Your Teacher Account hasn't been Approved yet!")
            store.dispatch(setCurrentUser(data));
          store.dispatch(showModal(false));
          setFormState({ loading: false });
        })
        .catch((err) => {
          error = true;
          console.log(err);
          setFormState({ loading: false });
        });
    } else {
      try {
        const { data } = await axios.post("/user/request_reset_email/", values);
        message.info(data.success);
        setFormState({ loading: false });
        store.dispatch(
          setModalObstrusive({
            closable: true,
            modalVisible: false,
            modalType: {
              isSignUp: false,
              width: 400,
              title: "Sign In",
            },
            accountType: "",
            obtrusive: false,
          })
        );
      } catch (err) {
        console.log(err);
        setFormState({ loading: false });
      }
    }

    if (error) {
      setAuthError("Error occured contact admin");
      setFormState({ loading: false });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
        size="large"
        layout="vertical"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>
        {!isPasswordReset && (
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
        )}
        {authError !== "" && <Typography type="danger">{authError}</Typography>}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={formState.loading}
          >
            {isPasswordReset ? "send reset email" : "Login"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignInForm;
