import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Empty from "antd/lib/empty";
import Layout from "antd/lib/layout";
import Typography from "antd/lib/typography";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer.component";
import uuid from "react-uuid";
import axios from "../../apis/axios";
import CommentsComponent from "../../components/Comments/CommentList.component";
// the hook
import { useTranslation } from "react-i18next";

import "./VideoPage.styles.css";

const { Content } = Layout;
const { Title } = Typography;

const VideoPage = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const { t } = useTranslation();
  const { topicId, videoId } = useParams();
  const history = useHistory();

  const url = `/content/videos/${topicId}/`;

  useEffect(() => {
    const getVideos = async () => {
      try {
        const { data } = await axios.get(url);

        // sorting data w.r.t to rating
        data.sort((a, b) => a.rating - b.rating);

        setVideos(data.reverse());
      } catch (err) {
        console.error(err);
      }
    };
    getVideos();
  }, [url]);

  useEffect(() => {
    if (videos)
      if (videoId === "notSelected") {
        setSelectedVideo(videos[0]);
      } else {
        const currentVideo = videos.find((item) => item.id === +videoId);
        setSelectedVideo(currentVideo);
      }
  }, [videoId, videos]);

  return (
    <Content className="video-page__content">
      <div className="video-page__player">
        {selectedVideo != null && (
          <>
            <VideoPlayer videoData={selectedVideo} />
            <CommentsComponent videoData={selectedVideo} />
          </>
        )}
      </div>
      <div className="video-page__side">
        <div className="disclaimer">
          <Title
            level={4}
            style={{ borderLeft: "3px solid red", paddingLeft: "1rem" }}
          >
            {t("disclaimer")}
          </Title>
          <Typography className="text">{t("disclaminerText")}</Typography>
        </div>
        <div className="videos">
          {videos.length > 0 ? (
            videos.map((video) => (
              <div
                className="more-video"
                onClick={() => history.push(`/watch/${topicId}/${video.id}`)}
                key={uuid()}
              >
                <img
                  src={`http://img.youtube.com/vi/${video.code}/0.jpg`}
                  key={uuid()}
                  alt={video.name}
                  className="video-thumbnail"
                />
                <Typography className="thumb-title">{video.name}</Typography>
              </div>
            ))
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </Content>
  );
};

export default VideoPage;
