import { Link } from "react-router-dom";
import uuid from "react-uuid";
import { useTranslation } from "react-i18next";

const StandardComponent = ({ color, std, board, medium, subjectsData }) => {
  const { t } = useTranslation();

  return (
    <div
      key={uuid()}
      style={{
        width: "300px",
        border: `2px solid ${color}`,
        borderRadius: "3rem",
        overflow: "hidden",
        padding: 0,
        background: "#fff",
        margin: "auto",
      }}
    >
      <div
        style={{
          backgroundColor: `${color}`,
          width: "300px",
          height: "110px",
          borderRadius: "0% 100% 49% 51% / 64% 61% 39% 36% ",
        }}
      >
        <h2
          style={{
            padding: "15px",
            color: `#fff`,
            fontWeight: "800",
            fontSize: "2.28rem",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: `${color}`,
          }}
        >
          {t("class")} {std}
        </h2>
      </div>
      <div>
        <ul
          style={{
            listStyle: "none",
          }}
        >
          {!subjectsData.loading &&
            subjectsData.data.map((item) => (
              <li
                key={uuid()}
                style={{
                  textAlign: "center",
                  fontSize: "1.8rem",
                  fontWeight: "600",
                  cursor: "pointer",
                  margin: "0.5rem",
                }}
              >
                <Link
                  to={`courses/${board.name}/${medium.name}/${std}/${item.name}`}
                  style={{ color: `${color}` }}
                >
                  {item.name}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default StandardComponent;
