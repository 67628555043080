import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axiosInstance from "axios";
import { Content } from "antd/lib/layout/layout";
import Skeleton from "antd/lib/skeleton";
import Button from "antd/lib/button/button";
import Table from "antd/lib/table";
import message from "antd/lib/message";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import backendAxios from "../../apis/axios";
import "./Download.styles.css";

const { Column } = Table;

const axios = axiosInstance.create({
  baseURL: process.env.REACT_APP_DOWNLOADER_URL,
  timeout: 5000,
});

const DownloadPage = (props) => {
  const [video, setVideo] = useState();
  const [videoDetails, setVideoDetails] = useState();
  const [availableFormats, setAvailableFormats] = useState();
  const history = useHistory();

  useEffect(() => {
    if (props.location?.state?.video) {
      console.log(props.location?.state?.video);
      setVideo(props.location?.state?.video);
      console.log("getting info");
      const getInfo = async () => {
        const { data } = await axios.get(
          `/get_info?url=${props.location?.state?.video.url}`
        );
        const formats = data.formats.filter(
          (item) => item.qualityLabel && item.hasAudio && item.hasVideo
        );
        setAvailableFormats(formats);
        setVideoDetails(data.videoDetails);
      };
      getInfo();
    } else history.replace("/");
  }, [props?.location?.state?.video, history]);

  const download = async (itag) => {
    const videoUrl = video.url;
    message.info("Video Download request sent... Please wait...");
    try {
      const { data } = await backendAxios.post("/user/download_tracker/", {
        video_id: video.id,
      });
      console.log(data);
      window.location.href = `${process.env.REACT_APP_DOWNLOADER_URL}/download?url=${videoUrl}&title=${videoDetails.title}&itag=${itag}`;
    } catch (err) {
      console.log(err);
    }
  };

  console.log(availableFormats);
  return (
    <Content style={{ minHeight: "100vh" }}>
      {videoDetails ? (
        <div className="download-page-container">
          <div className="video-details">
            <img src={videoDetails.thumbnails[0].url} alt="thumbnail" />
            <h3>{videoDetails.title}</h3>
          </div>
          <Table dataSource={availableFormats} pagination={false}>
            <Column title="Format" dataIndex="qualityLabel" />
            <Column
              key={Math.random()}
              title="Has Audio"
              dataIndex="hasAudio"
              render={(hasAudio) => (
                <>{hasAudio ? "Audio Available" : "Not Available"}</>
              )}
            />
            <Column
              key={Math.random()}
              title="Has Video"
              dataIndex="hasVideo"
              render={(hasVideo) => (
                <>{hasVideo ? "Video Available" : "Not Available"}</>
              )}
            />
            <Column
              title="Download"
              key="action"
              render={(item) => (
                <>
                  <Button
                    icon={<DownloadOutlined />}
                    size="medium"
                    type="link"
                    onClick={() => download(item.itag)}
                    className="mobile-View-btn"
                  >
                    Download
                  </Button>
                </>
              )}
            />
          </Table>
        </div>
      ) : (
        <Skeleton active />
      )}
    </Content>
  );
};

export default DownloadPage;
