import { useState } from "react";
import Card from "antd/lib/card";
import Button from "antd/lib/button/button";
import Input from "antd/lib/input";
import Layout from "antd/lib/layout/layout";
import Form from "antd/lib/form/Form";
import message from "antd/lib/message";
import axios from "../../apis/axios";
import { useParams, useHistory } from "react-router-dom";
import { store } from "../../redux/store";
import { setModalObstrusive } from "../../redux/modal/modal.actions";

const PasswordReset = () => {
  const { uidb64, token } = useParams();
  const history = useHistory();
  const [formState, setFormState] = useState({ loading: false });

  const onFinish = async (values) => {
    setFormState({ loading: true });
    try {
      const { data } = await axios.patch(
        `/user/reset_password/${uidb64}/${token}/`,
        values
      );
      message.info(`${data.message} Now Please Login!!!`);
      history.push("/");
      store.dispatch(
        setModalObstrusive({
          closable: false,
          modalVisible: true,
          modalType: {
            isSignUp: false,
            width: 400,
            title: "Sign In",
          },
          accountType: "",
          obtrusive: true,
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setFormState({ loading: false });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validatePassword = (rule, value, callback) => {
    if (value && value.length < 8) {
      callback("Your password must contain at least 8 characters.");
    } else if (!isNaN(value)) {
      callback("Your password can’t be entirely numeric.");
    } else {
      callback();
    }
  };

  return (
    <Layout
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Card bordered={true} style={{ padding: "1rem", width: 400 }}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          size="large"
          layout="vertical"
        >
          <Form.Item
            label="Password"
            name="password"
            hasFeedback
            rules={[
              { required: true, message: "Please input your password!" },
              { validator: validatePassword },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: "Please input your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={formState.loading}
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Layout>
  );
};

export default PasswordReset;
