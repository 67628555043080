import { ModalActionTypes } from "./modal.types";

const INITIAL_STATE = {
  closable: true,
  modalVisible: false,
  modalType: {
    isSignUp: false,
    width: 400,
    title: "Sign In",
  },
  accountType: "",
  obtrusive: false,
};

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL:
      return {
        ...state,
        modalVisible: action.payload,
      };
    case ModalActionTypes.SET_MODAL_CLOSABLE:
      return {
        ...state,
        closable: action.payload,
      };
    case ModalActionTypes.MODIFY_MODAL:
      return {
        ...state,
        modalType: action.payload,
      };
    case ModalActionTypes.SET_OBSTRUSIVE_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    case ModalActionTypes.SET_MODAL_ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.payload,
      };
    default:
      return state;
  }
};

export default modalReducer;
