import { useEffect } from "react";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import "./SubjectsTab.styles.css";

const SubjectsTab = ({ subjectsData, onTabSelected, activeTab, color }) => {
  useEffect(() => {
    if (activeTab === undefined) {
      onTabSelected(subjectsData[0].name);
    }
  });

  const moveLeft = () => {
    const index = subjectsData.indexOf(activeTab);
    const length = subjectsData.length;
    if (index === 0) onTabSelected(subjectsData[length - 1].name);
    else onTabSelected(subjectsData[index - 1].name);
  };

  const moveRight = () => {
    const index = subjectsData.indexOf(activeTab);
    const length = subjectsData.length;
    if (index === length - 1) onTabSelected(subjectsData[0].name);
    else onTabSelected(subjectsData[index + 1].name);
  };

  return (
    <>
      {activeTab && (
        <div className="subjectsTab">
          <LeftCircleFilled
            className="arrowBtn"
            style={{ color: `${color}` }}
            onClick={moveLeft}
          />
          <span className="subjectText" style={{ color: `${color}` }}>
            {activeTab.name}
          </span>
          <RightCircleFilled
            className="arrowBtn"
            style={{ color: `${color}` }}
            onClick={moveRight}
          />
        </div>
      )}
    </>
  );
};

export default SubjectsTab;
